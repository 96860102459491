import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import Layout from '../../../../components/shared/layout';
import SEO from '../../../../components/shared/SEO';
import * as privacyPolicyStyles from '../../../../styles/components/apps/krypto/privacy-policy/index.module.css';
import { isLightTheme } from '../../../../util';

const KryptoPrivacyPolicy = ({ currentTheme }) => {
  const appLink: string = `https://twitter.com/oyamzee`;
  const colorStyles = { color: isLightTheme(currentTheme) ? '#333' : '#e6e6e6' };

  return (
    <React.Fragment>
      <h1 style={colorStyles}>
        License/Disclaimer - <a href={appLink}>@oyamzee</a> twitter bot
      </h1>
      <p>
        THE SOFTWARE IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
        INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE
        FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
        OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER
        DEALINGS IN THE SOFTWARE.
      </p>
    </React.Fragment>
  );
};

const PrivacyPolicyWrapper = () => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state.theme);

  return (
    <Layout>
      <SEO title={'Privacy Policy · Krypto · Brian Njogu'} />
      <div
        className={`container ${privacyPolicyStyles.container} ${
          privacyPolicyStyles[`container_${currentTheme}`]
        }`}
      >
        <KryptoPrivacyPolicy currentTheme={currentTheme} />
      </div>
    </Layout>
  );
};

export default PrivacyPolicyWrapper;
